import {
  useLazyQuery as useApolloLazyQuery,
  TypedDocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
} from '@apollo/client'
import { getCookie } from 'cookies-next'
import { DocumentNode } from 'graphql'
import useCurrentLocale from './useCurrentLocale'

export const useLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
): LazyQueryResultTuple<TData, TVariables> => {
  const locale = useCurrentLocale()

  const optionsConfig = options ?? {
    variables: { locale },
    onCompleted: (data) => data,
    onError: (error) => error,
  }

  if (optionsConfig?.variables) {
    //@ts-ignore
    optionsConfig.variables.storeCode = locale
  } else {
    optionsConfig.variables = { locale }
  }

  //@ts-ignore
  return useApolloLazyQuery(query, {
    ...optionsConfig,
    context: {
      headers: {
        Store: locale,
        Authorization: `Bearer ${getCookie('userToken')}`,
      },
    },
  })
}
