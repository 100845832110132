'use client'

import { createContext, FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react'

interface ModalInterface {
  uuid: string
  component: ReactNode | null
}

export type ModalContextType = {
  modals: ModalInterface[]
  addModal: (modal: ModalInterface) => void
  removeModal: (id: string) => void
  clearModals: () => void
}

export const ModalContext = createContext<ModalContextType | null>(null)

const ModalProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<ModalInterface[]>([])

  const addModal = useCallback(
    (modal: ModalInterface) => {
      setModals((prevState) => [...prevState, modal])
    },
    [modals],
  )

  const clearModals = useCallback(() => {
    setModals([])
  }, [])

  const removeModal = useCallback(
    (uuid: string) => {
      setModals(modals.filter((modal) => uuid !== modal.uuid))
    },
    [modals],
  )
  const context = useMemo(
    () => ({
      modals,
      addModal,
      removeModal,
      clearModals,
    }),
    [addModal, modals, removeModal, clearModals],
  )

  return <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
}

export default ModalProvider
