import { CartItemPrices } from '../../../.mesh'
import { ProductHitAlgolia } from '../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { AlgoliaConfig } from './AlgoliaConfigContext.utils'
import { getIsUSALocale } from './locale.utils'


export const formatPriceByStoreAndLocale = (locale: string, prices?: CartItemPrices): Money => {
  if (getIsUSALocale(locale)) {
    return {
      value: prices?.price.value ?? 0,
      currency: prices?.price.currency ?? 'USD',
    }
  }

  return {
    value: prices?.price_including_tax.value ?? 0,
    currency: prices?.price_including_tax.currency ?? 'USD',
  }
}

export const getPriceFromProductHitAlgolia = (product: ProductHitAlgolia) => {
  if (!product.price) {
    return null
  }

  const firstPriceByCurrency = Object.values(product.price)?.[0]

  if (!firstPriceByCurrency) {
    return 0
  }

  return firstPriceByCurrency.default
}

export const formatPriceFromAlgoliaConfigPriceFormat = (
  price: number,
  algoliaConfig: AlgoliaConfig,
) => {
  const { groupSymbol, pattern } = algoliaConfig.priceFormat

  // format price with group symbol
  // ex: 91400 becomes 91.400 en uk_en
  // https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
  // const priceWithGroupSymbol = price.toString().replace(/\B(?=(\d{3}){1}(?!\d))/g, groupSymbol) replaced this with new regex that adds a comma every 3 digits (millions, billions, trillions...)
  const priceWithGroupSymbol = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, groupSymbol);

  // format price with pattern
  // ex: 91.400 becomes £91.400 en uk_en
  const priceWithPattern = pattern.replace('%s', priceWithGroupSymbol)

  return priceWithPattern
}
