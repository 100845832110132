'use client'

import { getCookie } from 'cookies-next'
import {
  MutationHookOptions,
  OperationVariables,
  DefaultContext,
  ApolloCache,
  DocumentNode,
  TypedDocumentNode,
  MutationTuple,
  useMutation as useApolloMutation,
} from '@apollo/client'
import useCurrentLocale from './useCurrentLocale'
import { useTranslation } from '../../presentation/context/ConfigContext'

export const useMutation = <
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const locale = useCurrentLocale()
  const userToken = getCookie('userToken')
  const t = useTranslation()

  const [mutationResult, mutationOptions] = useApolloMutation(mutation, {
    ...options,
    //@ts-ignore
    context: {
      headers: {
        Store: locale,
        Authorization: `Bearer ${userToken}`,
        //@ts-ignore
        ...options?.context?.headers,
      },
    },
  })

  if (mutationOptions.error?.message === 'Session expired') {
    window.location.href = `/customer/login?notification=${t(
      'customer-session-error-text',
      {},
      false,
    )}`
  }

  return [mutationResult, mutationOptions]
}
