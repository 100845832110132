import { Md5 } from 'ts-md5'
import { ProductHitAlgolia } from '../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { getPriceFromProductHitAlgolia } from './price.utils'
import { SelectionItem } from '../../domain/selectionItem'

export type GtmProduct = {
  item_name?: Product['gtm_name']
  item_id?: Product['sku']
  item_brand: 'Messika'
  price?: number | null
  item_variant?: string | (Variant | null)[]
  index?: number | string
  item_list_id?: string
  item_list_name?: string
  item_category?: string
  item_category1?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_category6?: string
}

export function createGtmProduct(
  product: Product | VariantProduct | GtmProduct | SelectionItem,
  params?: {
    position?: number | string
    listId?: string
    listName?: string
  },
): GtmProduct {
  const storedValues = JSON.parse(
    typeof window !== 'undefined' ? (window.localStorage.getItem('productValues') as any) : '{}',
  )
  const listId = params?.listId || (storedValues ? storedValues.listId : 'produit')
  const listName = params?.listName || (storedValues ? storedValues.listName : 'Produit')

  let productItem: GtmProduct = {
    item_brand: 'Messika',
    index: params?.position || '',
    item_variant: '',
    item_list_id: listId,
    item_list_name: listName,
  }
  if ('sku' in product) {
    productItem.item_name = product.gtm_name
    productItem.item_id = product.sku
    const priceRange: Product['priceRange'] =
      'price_range' in product ? product.price_range : product.priceRange
    productItem.price = priceRange?.minimum_price.regular_price.value
    const gtmCategoryObj = gtmCategory(product?.gtm_categories)
    productItem = { ...productItem, ...gtmCategoryObj }
  }
  if ('variants' in product) {
    productItem.item_variant = product.variants ?? ''
  }
  return productItem
}

export function gtmProductPush(params: {
  product: GtmProduct | VariantProduct | Product
  event: string
  listId?: string
  listName?: string
  position?: number | string
}) {
  const { event, listId, listName, position } = params
  const product = createGtmProduct(params.product, {
    listName,
    listId,
    position,
  })
  const listIdValue = listId ?? 'categorie'
  const listNameValue = listName ?? "Catégorie d'Articles"

  gtmPush({
    event,
    ecommerce: {
      item_list_id: product.item_list_id,
      item_list_name: product.item_list_name,
      items: {
        ...product,
      },
    },
  })

  const productValues = {
    position: position,
    listId: listIdValue,
    listName: listNameValue,
  }

  localStorage.setItem('productValues', JSON.stringify(productValues))
}

export function gtmPush(gtmEvent: any) {
  /* @ts-ignore */
  let dataLayerLogger = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    push: (args: Record<string, any>) => ({}),
  }

  /* eslint-disable valid-typeof */
  if (typeof window !== undefined && window.dataLayer) {
    dataLayerLogger = window.dataLayer
  }
  if (process.env.NODE_ENV === 'development') {
    console.log(gtmEvent)
  }
  dataLayerLogger.push(gtmEvent)
}

export function gtmCategory(item: string | undefined): {
  item_category?: string
  item_category1?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_category6?: string
} {
  const categorieProp = {}
  const categorie = item

  categorie
    ?.split('/')
    .filter((n) => n)
    .map((cat, index) => {
      index === 0
        ? (categorieProp[`item_category`] = cat)
        : (categorieProp[`item_category${index + 1}`] = cat)
    })
    .slice(0, 4)

  return categorieProp
}

export async function sha256(message: string | number | null | undefined) {
  if (message === null || message === undefined) {
    return ''
  }
  const msgBuffer = new TextEncoder().encode(`${message}`)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export function md5Encrypt(value: (string | number) | undefined | null) {
  return value ? Md5.hashStr(value?.toString()) : ''
}

type GtmTrackerEvents = {
  add: (ev: { product: ProductHitAlgolia | SelectionItem }) => void
}

export const GtmViewItemListTracker = {
  queue: [] as Record<string, any>[],
  skus: [] as string[],
  listeners: {
    add: [],
  } as Record<keyof GtmTrackerEvents, ((ev: any) => void)[]>,
  reset() {
    this.queue = []
    this.skus = []
    this.listeners = {
      add: [],
    }
  },
  addProductHitAlgolia(product: ProductHitAlgolia, meta?: Record<string, any>) {
    const sku = Array.isArray(product.sku) ? product.sku[0] : product.sku
    if (this.skus.includes(sku)) {
      return
    }
    const gtmItem = {
      ...meta,
      item_brand: 'Messika',
      item_name: product.gtm_name ?? product.name,
      item_id: sku,
      price: getPriceFromProductHitAlgolia(product),
      ...gtmCategory(product.categories.level0?.join('/')),
    }
    this.queue.push(gtmItem)
    this.skus.push(sku)
    this.listeners['add'].forEach((cb) => cb({ product }))
  },
  addSelectionItem(selectionItem: SelectionItem, meta?: Record<string, any>) {
    if (!selectionItem.sku || this.skus.includes(selectionItem.sku)) {
      return
    }
    this.queue.push({
      ...meta,
      item_brand: 'Messika',
      item_name: selectionItem.gtm_name || selectionItem.name || selectionItem.sku,
      item_id: selectionItem.sku,
      price: selectionItem.priceRange?.minimum_price.regular_price,
      ...gtmCategory(selectionItem.gtm_categories),
    })
    this.skus.push(selectionItem.sku)
    this.listeners['add'].forEach((cb) => cb({ product: selectionItem }))
  },
  addEventListener<Event extends keyof GtmTrackerEvents>(
    event: Event,
    fn: GtmTrackerEvents[Event],
  ) {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push(fn)
  },
  pushEvent(params: { item_list_name: string; item_list_id: string }) {
    if (this.queue.length === 0) {
      return
    }
    const gtmItems = this.queue.map((item) => {
      return {
        ...item,
        item_list_name: params.item_list_name,
        item_list_id: params.item_list_id,
      }
    })
    gtmPush({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: params.item_list_name,
        item_list_id: params.item_list_id,
        items: gtmItems,
      },
    })
    this.queue = []
    this.skus = []
  },
}
