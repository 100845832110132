'use client'

import { ApolloError } from '@apollo/client'

import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useCurrentLocale from '../../core/hook/useCurrentLocale'
import { useMutation } from '../../core/hook/useMutation'
import { createCustomerDocument } from '../../queries/CreateCustomer.gql'
import { generateCustomerTokenDocument } from '../../queries/GenerateCustomerToken.gql'
import { changeStoreUtils } from '../../domain/store'
import { useLazyQuery } from '../../core/hook/useLazyQuery'
import { CustomerDocument } from '../../queries/Customer.gql'
import { md5Encrypt } from '../utils/gtmHelpers'

type RegisterFormType = {
  newsletter: boolean
  terms?: boolean
  birthDate: string
  firstName: string
  lastName: string
  email: string
  emailConfirmation: string
  password: string
  passwordConfirmation: string
  title?: string | undefined
  wechatId?: string
}

export type UserContextType = {
  hydrated: boolean
  userToken: string | null | undefined
  login: (
    email: string,
    password: string,
    recaptchaToken?: string,
    forceLogout?: boolean,
  ) => Promise<{ success: boolean; userToken: string | null; error?: string }>
  registration: (
    userToRegister: RegisterFormType,

    recaptchaToken?: string,
  ) => Promise<{ success: boolean; error?: string }>
  logout: () => void
  isLogged: boolean
  user: Customer | null
  isLoadingUser: boolean
  reloadUserInformation: () => void
}

export const UserContext = createContext<UserContextType>({
  hydrated: false,
  userToken: null,
  login: () => Promise.resolve({ success: false, userToken: null }),
  registration: () => Promise.resolve({ success: false }),
  logout: () => {},
  isLogged: false,
  isLoadingUser: true,
  user: null,
  reloadUserInformation: () => {},
})

const UserProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [GenerateCustomerToken] = useMutation(generateCustomerTokenDocument)
  const [CreateCustomer] = useMutation(createCustomerDocument)
  const [userToken, setUserToken] = useState<string | null>(String(getCookie('userToken')))
  const currentLocale = useCurrentLocale()
  const [user, setUser] = useState<Customer | null>(null)
  const [Customer, { loading }] = useLazyQuery(CustomerDocument, {
    fetchPolicy: 'no-cache',
  })

  const hydrated = useMemo(() => {
    if (!userToken) {
      return true
    }
    return Boolean(user)
  }, [userToken, user])

  useEffect(() => {
    const userTokenCookie = getCookie('userToken')
    const token = userTokenCookie ? String(userTokenCookie) : null
    setUserToken(token)
  }, [])

  useEffect(() => {
    if (userToken) {
      loadUser()
    }
  }, [userToken])

  const logout = useCallback(() => {
    setUserToken(null)
    setUser(null)
    deleteCookie('userToken', {
      path: '/' + currentLocale,
    })
    deleteCookie('userEmailEncrypted', {
      path: '/' + currentLocale,
    })

    changeStoreUtils()
  }, [])

  const login = useCallback(
    async (email: string, password: string, captchaToken?: string, forceLogout?: boolean) =>
      GenerateCustomerToken({
        variables: {
          email,
          password,
        },
        context: {
          headers: {
            Store: currentLocale,
            'X-ReCaptcha': captchaToken || '',
          },
        },
      })
        .then((response) => {
          if (response.data?.generateCustomerToken) {
            if (forceLogout) {
              logout()
            }
            const expiresAt = new Date()
            expiresAt.setSeconds(
              expiresAt.getSeconds() + Number(process.env.NEXT_PUBLIC_USER_TOKEN_DURATION),
            )

            setCookie('userToken', response.data?.generateCustomerToken.token, {
              expires: expiresAt,
              path: '/' + currentLocale,
            })
            setUserToken(response.data?.generateCustomerToken.token || null)

            return {
              success: true,
              userToken: response.data?.generateCustomerToken?.token || null,
            }
          }

          throw Error(response.errors && response.errors[0] && response.errors[0].message)
        })
        .catch((e: ApolloError) => ({
          success: false,
          error: e.message,
          userToken: null,
        })),
    [GenerateCustomerToken, logout],
  )

  const registration = useCallback(
    (userToRegister: RegisterFormType, captchaToken?: string) =>
      CreateCustomer({
        variables: {
          email: userToRegister.email,
          prefix: userToRegister.title,
          password: userToRegister.password,
          firstName: userToRegister.firstName,
          lastName: userToRegister.lastName,
          dateOfBirth: userToRegister.birthDate,
          isSubscribed: userToRegister.newsletter,
          wechatId: userToRegister.wechatId,
        },
        context: {
          headers: {
            Store: currentLocale,
            'X-ReCaptcha': captchaToken || '',
          },
        },
      })
        .then(() => ({
          success: true,
        }))
        .catch((error: ApolloError) => ({
          success: false,
          error: error.message,
        })),
    [],
  )

  const loadUser = useCallback(() => {
    if (getCookie('userToken')) {
      Customer().then((customer) => {
        if (customer.data?.customer) {
          setCookie('userEmailEncrypted', customer.data.customer.email, {
            path: '/' + currentLocale,
          })
          setCookie('customerIdEncrypted', md5Encrypt(customer.data.customer.customer_id), {
            path: '/' + currentLocale,
          })
          setUser(customer.data?.customer as Customer | null)
          return
        }

        logout()
      })
    }
  }, [Customer])

  const context = useMemo(
    () => ({
      hydrated,
      userToken,
      login,
      registration,
      logout,
      isLogged: Boolean(userToken),
      user,
      isLoadingUser: loading,
      reloadUserInformation: loadUser,
    }),
    [userToken, login, registration, logout, user, loading, loadUser, hydrated],
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserProvider
